import React, {useCallback} from 'react';
import { Check, ArrowRight } from "@material-ui/icons";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { setOrder } from "../../redux/actions";
import Location from './Location';
import Style from './Style';
import Text from './Text';
import Finish from './Finish';

const useStyles = makeStyles({
  tabContent: props => {
    if(props.matches === false) {
      return ({
        float: 'left',
        padding: '0 0px',
        borderLeft: 'none',
        height: '300px',
        width: 'calc(100% - 100px)',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
        padding: '20px',
      })
    } else {
      return ({
        maxHeight: 'calc(95vh - 80vw)',
        width: '100%',
        bottom: '3.44rem',
        left: 0,
        right: 0,
        top: 'auto',
        overflowY: 'scroll',
        overflowX: 'hidden',
        float: 'left',
        margin: '0 0 64px',
      })
    }
  },
  buttonContainer: props => {
    if(props.matches === false) {
      return {
        width: '80%',
        minHeight: '43px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '20px',
        backgroundColor: '#ffd166',
        padding: '10px',
        borderRadius: '20px',
        cursor: 'pointer',
      }
    } else {
      return {
        width: '80%',
        minHeight: '43px',
        backgroundColor: '#ffd166',
        padding: '5px',
        margin: 'auto',
        borderRadius: '20px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: '40px',
        marginTop: '25px'
      }
    }
  },
  circular: {
    marginLeft: '10px',
    color: 'black'
  }
})

const elms = [Location, Style, Text, Finish]

// send request for updated cart..

const ToolArea = ({selectedIndex, setSelectedIndex}) => {
  const matches = useMediaQuery('(max-width:800px)')
  const classes = useStyles({matches});
  const isLoading = useSelector(state => state.map.isLoading);
  const dispatch = useDispatch();
  const updateOrder = useCallback(
    (state) => dispatch(setOrder(state)),
    [dispatch] 
  )

  return (
    <div className={classes.tabContent}>
    {
      matches && (
        <div 
          className="close-btn" 
          style={{ display: selectedIndex === -1 ? 'none' : 'block' }} 
          onClick={() => setSelectedIndex(-1)}
        >
          <img src={require('../../images/mult.png')} title="Multiply" width="30" height="30" />
        </div>
      )
    }
    

      {
        elms.map((_item, index) => (
          <div 
            style={{ display: selectedIndex === index ? 'block' : 'none', width: '100%' }}
            key={index}
          >
            <_item />
          </div> 
        ))
      }

      {(selectedIndex !== 3 && selectedIndex !== -1) &&
        <div 
          className={classes.buttonContainer}
          onClick={() => setSelectedIndex(selectedIndex + 1)}
        >
           Next step 
           <ArrowRight />
        </div>
      }
      {(selectedIndex == 3 && selectedIndex !== -1) &&
        <div 
          className={classes.buttonContainer}
          onClick={() => updateOrder(true)}
        >
          <Check /> 
          Order my map 
          {isLoading && <CircularProgress className={classes.circular} size={20}  />}
        </div>
      }
    </div>
  )
}

export default ToolArea;
