import { createMuiTheme } from '@material-ui/core/styles';

// material-ui theme overrides
const theme = createMuiTheme({
  typography: {
    fontFamily: 'var(--primary-font-family)',
  },
  palette: {
    primary: {
      light: '#c5efec',
      main: '#8aded8',
      dark: '#4ecdc4',
      contrastText: '#ffffff',
    },
    secondary: {
      main: '#f5d76e',
    },
    error: {
      main: '#f95c69',
    },
    text: {
      primary: '#393939',
      secondary: '#777777',
      disabled: '#b3b3b3',
      hint: '#bebebe',
    },
  },
});

export { theme };
