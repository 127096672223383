/**
 * For Minimumwidth : 670 
 *    toolBarwidth = 500 
 *    mapWidth = totalWidth - 500
 * 
 * For MinimumWidth < 670 
 *    move it downwards 
 */

import {useState, useLayoutEffect} from 'react'
import { makeStyles } from '@material-ui/core/styles';

export const useWindowSize = () => {
  const [size, setSize] = useState([0,0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  return size;
}


export const useStyles = makeStyles({
  toolArea: props => ({
    backgroundColor: '#06d6a0',
    width: '500px',
    minWidth: '500px !important',
    height: props.height,
  }),
  mapArea: props => ({
    backgroundImage: `url(${require('../images/background.png')})`,
    width: `${props.width - 500}px`,
    height: props.height
  })  
})

