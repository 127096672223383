import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  title: props => {
    let ret_ = {
      fontSize: '120%',
      color: '#fffcf9',
      fontFamily: 'Actor, sans-serif',
      textAlign: 'center',
      fontWeight: 300,
      lineHeight: 1.1,
      width: '100%',
      marginButton: 0
    };
    if(!props.matches) {
      ret_.fontSize = '36px'
    } 
    return ret_; 
  },
  buttonContainer: props => {
    if(props.matches === false) {
      return {
        width: '90%',
        minHeight: '33px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flexStart',
        alignItems: 'center',
        marginBottom: '5px',
        marginTop: '5px',
        backgroundColor: 'white',
        padding: '5px',
        borderRadius: '20px',
        paddingLeft: '10px',
        paddingRight: '10px',
        color: '#26a69a',
        cursor: 'pointer',
      }
    } else {
      return {
        width: '90%',
        minHeight: '33px',
        backgroundColor: 'white',
        padding: '5px',
        marginLeft: 'auto',
        marginRight: 'auto',
        borderRadius: '20px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flexStart',
        paddingLeft: '10px',
        paddingRight: '10px',
        alignItems: 'center',
        color: '#26a69a',
        marginBottom: '7px',
        marginTop: '7px'
      }
    }
  },
  spacer: props => {
    if(props.matches) {
      return {
        marginTop: '50px'
      }
    }
  },
  styleContainer: props => {
    if(props.matches === false) {
      return {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
      }
    } else {
      return {
        width: '100%'
      }
    }
  },
  displayName: {
    fontWeight: 700,
    paddingRight: '7px',  
  },
  flexGrow: {
    flexGrow: 1
  },
  checkbox: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%',
    padding: '0% 5%'
  },

}));