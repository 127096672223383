import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  title: props => {

    let ret_ = {
      fontSize: '120%',
      color: '#fffcf9',
      fontFamily: 'Actor, sans-serif',
      textAlign: 'center',
      fontWeight: 300,
      lineHeight: 1.1,
      width: '100%'
    };
    if(!props.matches) {
      ret_.fontSize = '36px'
    } 
    return ret_; 
  },
  styleContainer: props => {
    if(props.matches === false) {
      return {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        width: '100%',
        flexWrap : 'wrap',
        marginLeft : '30px'
      }
    } else {
      return {
        display: 'flow-root',
        whiteSpace: 'nowrap',
        height: '88px',
        overflowX: 'scroll'
      }
    }
  },
  styleItem: {
    width: '72px',
    height: '72px',
    minWidth: '72px',
    minHeight: '72px',
    margin: '3px',
    borderRadius: '25px'
  }
}));
