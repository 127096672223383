import React, {useState, useEffect, useCallback} from 'react';
import { mdiSetRight } from '@mdi/js';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {useSelector, useDispatch } from 'react-redux'
import { setStyle } from "../../redux/actions";
import {styles} from '../../constants';
import { useStyles } from './Style.style'
import './Style.css';

const Style = () => {

  const matches = useMediaQuery('(max-width:800px)')
  const classes = useStyles({matches});

  const style = useSelector(state => state.map.style);

  const dispatch = useDispatch();
  const updateStyle = useCallback(
    (style) => dispatch(setStyle(style)),
    [dispatch]
  )

  const onStyleClick = (type) => {
    updateStyle({...style, type})
  }

  const getStyle = (type) => {
    let _class = "snip1545";
    if(type === style.type) {
      _class += " hover";
    }
    return _class;
  }

  return (
    <>
      <h1 className={classes.title}> Choose your style </h1>
      <div className={classes.styleContainer}>
        {styles.map((item) => (
          <figure className={getStyle(item.name)} key={item.name} onClick={() => onStyleClick(item.name)}>
            <img src={item.src}  />
            <figcaption>
              <div>
                <i className="ion-ios-home-outline"></i>
              </div>
            </figcaption>
          </figure>
          
        ))}
      </div>
    </>
  )
}

export default Style;

