import React, {useCallback} from 'react';
import { Typography, InputBase, SvgIcon, IconButton, Button, Checkbox
} from '@material-ui/core';
import {useSelector, useDispatch} from 'react-redux';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {setSize, setStyle} from '../../redux/actions'
import { useStyles} from './Finish.style'
import { sizes } from "../../constants";

const Finish = () => {

  const matches = useMediaQuery('(max-width:800px)')
  const classes = useStyles({matches});
  const dispatch = useDispatch();
  const size = useSelector(state => state.map.size);
  const style = useSelector(state => state.map.style);
  const updateSize = useCallback(
    (size) => dispatch(setSize(size)),
    [dispatch]
  )

  const selectSize = (index) => {
    updateSize({...size, index});
  }

  const updateStyle = useCallback(
    (style) => dispatch(setStyle(style)),
    [dispatch]
  );

  const updateHorizontal = () => {
    const {isHorizontal } = style;
    updateStyle({...style, isHorizontal : !isHorizontal})
  }

  const updateFrame = () => {
    const {isFramed} = size;
    updateSize({...size, isFramed : !isFramed});
  }

  

  return (
    <>
      <h1 className={classes.title}> Choose your size </h1>
      <div className={classes.styleContainer}>
        {sizes.map((sz, index) => (
            <div 
            key={index}
            className={classes.buttonContainer}
            style={{ backgroundColor: size.index === index ? '#26a69a' : 'white',
            color: size.index !== index ? '#26a69a' : 'white'
          }}
            onClick={() => selectSize(index)}
            >
              <div className={classes.displayName}> {sz.displayName} </div>
              <div className={classes.size}> {sz.name} </div>
              <div className={classes.flexGrow} />
              <div className={classes.price}> ₹ {size.isFramed ? sz.priceINRFrame : sz.priceINR} </div>
            </div>
        ))}
      </div>
      <div className={classes.checkbox}>
        <Checkbox
          checked={style.isHorizontal}
          onChange={updateHorizontal}
        />
        <Typography variant="body1">Horizontal Layout</Typography>
      </div>
      <div className={classes.checkbox}>
        <Checkbox
          checked={size.isFramed}
          onChange={updateFrame}
        />
        <Typography variant="body1">Add Wooden Frame</Typography>
      </div>
    </>
  )
}

export default Finish;


