export const suggestions = [ 'Amsterdam', 'Italy', 'Prague', 'Mumbai'];

export const styles = [
  {
    name: 'pencilorama',
    src: 'https://kaartenzijncool.printmijnstad.nl/pencilorama/13/4207/2692.png'
  },
  {
    name: 'classic',
    src: 'https://kaartenzijncool.printmijnstad.nl/classic/13/4207/2692.png'
  },
  {
    name: 'impact-black',
    src: 'https://kaartenzijncool.printmijnstad.nl/impact-black/13/4207/2692.png'
  },
  {
    name: 'mono',
    src: 'https://kaartenzijncool.printmijnstad.nl/mono/13/4207/2692.png'
  },
  {
    name: 'antique',
    src: 'https://kaartenzijncool.printmijnstad.nl/antique/13/4207/2692.png'
  },
  {
    name: 'lo-fi',
    src: 'https://kaartenzijncool.printmijnstad.nl/lo-fi/13/4207/2692.png'
  },
  {
    name: 'coral-sunset',
    src: 'https://kaartenzijncool.printmijnstad.nl/coral-sunset/13/4207/2692.png'
  },
  {
    name: 'empire-green',
    src: 'https://kaartenzijncool.printmijnstad.nl/empire-green/13/4207/2692.png'
  },
  {
    name: 'baby-blue',
    src: 'https://kaartenzijncool.printmijnstad.nl/baby-blue/13/4207/2692.png'
  },
  {
    name: 'pink-cove',
    src: 'https://kaartenzijncool.printmijnstad.nl/pink-cove/13/4207/2692.png'
  },
  {
    name : 'metropolis',
    src: 'https://kaartenzijncool.printmijnstad.nl/metropolis/13/4207/2692.png'
  },
  {
    name: 'cold-horizon',
    src: 'https://kaartenzijncool.printmijnstad.nl/cold-horizon/13/4207/2692.png'
  },
  {
    name: 'minimal',
    src: 'https://kaartenzijncool.printmijnstad.nl/minimal/13/4207/2692.png'
  },
  {
    name: 'urban',
    src: 'https://kaartenzijncool.printmijnstad.nl/urban/13/4207/2692.png'
  },
  {
    name: 'black-lantern',
    src: 'https://kaartenzijncool.printmijnstad.nl/black-lantern/13/4207/2692.png'
  }
]

export const sizes = [
  {
    width: 11,
    height: 17,
    sizePostfix: "inch",
    name: "11x17inch",
    displayName: "Small",
    priceINR: 599,
    priceINRFrame: 899,
    variantID: '35763929186468',
    variantFrameID: '35763929219236'
  },
  {
    width: 18,
    height: 24,
    sizePostfix: "inch",
    name: "18x24inch",
    displayName: "Medium",
    priceINR: 999,
    priceINRFrame: 1499,
    variantID: '35763929252004',
    variantFrameID: '35763929284772'
  },
  {
    width: 24,
    height: 36,
    sizePostfix: "inch",
    name: "24x36inch",
    displayName: "Large",
    priceINR: 1399,
    priceINRFrame: 1999,
    variantID: '35763929317540',
    variantFrameID: '35763929350308'
  }
];
