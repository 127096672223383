import produce from 'immer';
import * as constants from '../constants';

export const initialState = {
  location : {
    lat: 52.3669,
    lng: 4.8944091,
    name: '',
    zoom: 12,
    showCityLabels: false,
  },
  style: {
    type: 'classic',
    isHorizontal: false,
  },
  text: {
    title: 'AMSTERDAM',
    subtitle: "N52º22'13\" E4º53'43\"",
    useCoordinate: true,
    isHidden: false,
  },
  size: {
    index: 1,
    isFramed: false
  },
  order: false,
  isLoading: false,
}

export default function map(state=initialState, action) {

  switch(action.type) {
    case constants.SET_LOCATION:
      return produce(state, (draft) => {
        const {location} = action;
        draft.location = location;
      })
    
    case constants.SET_SIZE:
      return produce(state, (draft) => {
        const {size} = action;
        draft.size = size;
      })
    
    case constants.SET_STYLE:
      return produce(state, (draft) => {
        const {style} = action;
        draft.style = style;
      })
    
    case constants.SET_TEXT:
      return produce(state, (draft) => {
        const {text} = action;
        draft.text = text;
      })

    case constants.SET_ORDER:
      return produce(state, (draft) => {
        const {state} = action;
        draft.order = state;
      })
    
    case constants.SET_IS_LOADING:
      return produce(state, (draft) => {
        const { state } = action;
        draft.isLoading = state;
      })

    default:
      return initialState;
  }
}