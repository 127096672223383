import React from 'react';
import {ThemeProvider } from '@material-ui/core/styles';
import { createStore } from 'redux';
import { Provider as ReduxProvider } from 'react-redux';
import {theme} from './styles/mui-theme';
import Base from './components/Base/Base'
import rootReducer from './redux/reducers'

export const store = createStore(rootReducer);

const Root = () => {
  return (
    <ReduxProvider store={store}>
      <ThemeProvider theme={theme}>
        <Base />
      </ThemeProvider>
    </ReduxProvider>
  )
}

export default Root;