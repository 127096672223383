import React, {useState, useEffect} from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
 

const ACTIVE_COLOR = 'rgba(0,0,0,.1)'
const HOVER_COLOR = 'rgba(0,0,0,.2)'

const useStyles = makeStyles({
  button: props => {
    if (props.matches) {
      return ({
        width: '25%',
        height: '64px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        float: 'left',
        '& img' : {
          width: '25px',
          height: '25px'
        }
      });
    } else {
      return ({
        width: '100px',
        height: '100px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        '& img' : {
          width: '50px',
          height: '50px'
        }
      });
    }
  },
  buttonInactive: {
    backgroundColor: 'transparent',
    '&:hover' : {
      backgroundColor: HOVER_COLOR
    }
  },
  buttonActive: {
    backgroundColor: ACTIVE_COLOR
  }
})


const TabButtons = ({options, selectedIndex, setSelectedIndex}) => {

  const matches = useMediaQuery('(max-width:800px)')
  const classes = useStyles({selectedIndex, matches});
  
  const updateSelectedIndex = (index) => {
    if(selectedIndex === index && matches === true) {
      setSelectedIndex(-1);
      return;
    }
    setSelectedIndex(index);
  }

  const getClassName = (index) => {
    let className = classes.button
    if(selectedIndex === index ) {
      className += ' ' + classes.buttonActive
    } else {
      className += ' ' + classes.buttonInactive
    }
    return className;
  }
 
  return (
    <>
      {options.map((item, index) => {
        const className = getClassName(index);
        return (
        <div 
          className={className}
          key={index}
          onClick={() => updateSelectedIndex(index)}
        >
          <img src={require(`../../images/${item.icon}.png`)} />
          <div id="text"> {item.name} </div>
        </div>
      )})}
    </>
    )

}

export default TabButtons;