import React, {useCallback} from 'react';
import { Typography, InputBase, SvgIcon,  Button, Checkbox
} from '@material-ui/core'
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { mdiMagnify } from '@mdi/js';
import axios from 'axios';
import { useSelector, useDispatch } from "react-redux";
import {suggestions} from '../../constants';
import {useStyles} from './Location.style';
import { setLocation } from "../../redux/actions";
import { setText } from "../../redux/actions"
import { convertDMS } from "../../utils/latlongtoDMS";

const Location = () => {

  const matches = useMediaQuery('(max-width:800px)')
  const classes = useStyles({matches});

  const location = useSelector(state => state.map.location);
  const text = useSelector(state => state.map.text);
  const dispatch = useDispatch();

  const updateLocation = useCallback(
    (location) => dispatch(setLocation(location)),
    [dispatch]
  );

  const updateText = useCallback(
    (text) => dispatch(setText(text)),
    [dispatch]
  );

  const onLocationChange = (e) => {
    // setNew location
    updateLocation({...location, name: e.target.value});
    
  }
  const onSubmit = async (e, name) => {
    if(!name) {
      name = location.name
    }
    if(e)
      e.preventDefault();
    const uri = "https://maps.googleapis.com/maps/api/geocode/json?key=AIzaSyAgptgbRLVo1bv4Wtzj_Q-z75P7Q8EmOtk&address=" + name;
    const res = await axios.get(uri);
    const data = res.data;
    if(data.results.length === 0) {
      return;
    }
    const lat = data.results[0].geometry.location.lat;
    const lng = data.results[0].geometry.location.lng;
    updateLocation({...location, lat,lng, name});
    updateText({...text, title: name.toUpperCase(), subtitle: convertDMS(lat, lng)});
  }

  const setCityLabels = () => {
    const {showCityLabels} = location;
    updateLocation({...location, showCityLabels: !showCityLabels});
  }

  const onSuggestionButtonClick = async (name) => {
    updateLocation({...location, name});
    onSubmit(null, name);
  }

  return(
    <>
      <h1 className={classes.title}> Find your favorite place </h1>
      <form className={classes.form} onSubmit={onSubmit}>
        <InputBase
          placeholder="Enter place"
          classes={{
            root: classes.inputRoot,
            input: classes.inputInput,
          }}
          value={location.name}
          onChange={onLocationChange}
        />
      <Button className={classes.iconBase} onClick={onSubmit}>
        <SvgIcon >
          <path d={mdiMagnify} />
        </SvgIcon>
      </Button>
      </form>
      <div className={classes.checkbox}>
        <Checkbox
          checked={location.showCityLabels}
          onChange={setCityLabels}
        />
        <Typography variant="body1"> Show city names on the map</Typography>
      </div>
      {matches === false &&
        (<>
          <div className={classes.rootDefaultOptions}>
            <Typography variant="h4">Or try our favorites!</Typography> 
          </div>
          <div className={classes.options}>
            {suggestions.map((item) => (
              <Button 
              key={item}
              color="primary"
              variant="contained"
              className={classes.suggestionButton}
              onClick={() => onSuggestionButtonClick(item)}
              >
                {item} 
              </Button>
            ))}
          </div>
        </>)
      }
    </>
  )

}

export default Location;