
import axios from 'axios'
import { sizes } from "../constants";

const makeid = (length) => {
  var result           = '';
  var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  var charactersLength = characters.length;
  for ( var i = 0; i < length; i++ ) {
     result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}



const redirectTOURI = (imgKey, location, style, text, size) => {
  let baseURI = "http://craftedmaps.in/cart/add?"
  let imageURI = "https://editor.craftedmaps.in/api/"
  if(size.isFramed) {
    baseURI += 'id=' + sizes[size.index]['variantFrameID'] + '&';
  } else {
    baseURI += 'id=' + sizes[size.index]['variantID'] + '&';
  }
  if(text.isHidden === false) {
    baseURI += 'properties[title]=' + text.title + '&'
    baseURI += 'properties[subtitle]=' + text.subtitle + '&'
  }
  baseURI += 'properties[style]=' + style.type + '&';
  baseURI += 'properties[_preview]=' + imageURI + imgKey + '&';
  baseURI += 'properties[_showlabels]=' + location.showCityLabels + '&';
  baseURI += 'properties[_lat]=' + location.lat + '&';
  baseURI += 'properties[_lng]=' + location.lng + '&';
  baseURI += 'properties[_zoom]=' + location.zoom + '&';
  baseURI += 'properties[_isHorizontal]=' + style.isHorizontal + '&';
  window.location.href = baseURI;
}

export const addToCart = async ({
  imgURL,
  location,
  style,
  text,
  size
}) => {
  const key = makeid(5);
  await axios.post('https://editor.craftedmaps.in/api/', { value: imgURL, key })
  redirectTOURI(key, location, style, text, size);
  return Promise.resolve();
}




