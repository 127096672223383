import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  title: props => {
    let ret_ = {
      fontSize: '120%',
      color: '#fffcf9',
      fontFamily: 'Actor, sans-serif',
      textAlign: 'center',
      fontWeight: 300,
      lineHeight: 1.1,
      width: '100%',
      marginButton: 0
    };
    if(!props.matches) {
      ret_.fontSize = '36px'
    } 
    return ret_; 
  },
  formItem: props => {
    let ret_ = {
      display: 'flex',
      flexDirection : 'column',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
      width: '100%',
      '& label': {
        padding: '5px 0'
      },
      padding: '0% 8%',
      marginTop: 0
    }
    return ret_;
  },
  inputRoot: {
    backgroundColor: 'white',
    width: '100%',
    height: '43px',
    marginButton: '5px !important'
  },
  disabled: {
    cursor: 'not-allowed'
  },
  inputInput: {
    paddingLeft: theme.spacing(2),
    padding: theme.spacing(1, 1, 1, 0),
  },
  checkbox: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%',
    padding: '0% 5%'

  },

}));