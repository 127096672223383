
import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  title: props => {

    let ret_ = {
      fontSize: '120%',
      color: '#fffcf9',
      fontFamily: 'Actor, sans-serif',
      textAlign: 'center',
      fontWeight: 300,
      lineHeight: 1.1,
      width: '100%'
    };
    if(!props.matches) {
      ret_.fontSize = '36px'
    } 
    return ret_; 
  },
  form: {
    position : 'relative',
    width: '100%',
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center'
  },
  inputRoot: {
    backgroundColor: 'white',
    width: '70%',
    height: '43px',
  },
  inputInput: {
    paddingLeft: theme.spacing(2),
    padding: theme.spacing(1, 1, 1, 0),
  },
  iconBase: {
    backgroundColor: 'black',
    borderRadius: 0,
    height: '43px',
    textAlign: 'center',
    '& path': {
      color: 'white'
    },
    '&:hover': {
      backgroundColor: 'black'
    }
  },
  checkbox: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%',
    paddingLeft: theme.spacing(1.5)
  },
  options: {
    display: 'flex',
    marginTop: '30px',
    width: '100%',
    padding: 0,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'flex-start',
    flexWrap: 'wrap'  
  },
  suggestionButton: {
    margin: '5px 5px',
    width: '46%',
    height: '43px',
    borderRadius: '30px',
    textTransform: 'none',
    backgroundColor: '#2780e3',
    color: '#ffffff',
    fontWeight: 'normal'
  },
  rootDefaultOptions: {
    color: 'white',
    marginTop: 10,
    '& h4' : {
      fontSize: '30px',
      fontWeight: 300,
      textAlign: 'center'
    },
  },

}))
