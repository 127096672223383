import React, {useState, useEffect, useCallback} from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import TabButtons from '../TabButtons'
import ToolArea from '../ToolArea';
import MapArea from '../MapArea';
import './Base.css'
import "leaflet/dist/leaflet.css";

const Base = () => {

  const options = [
    {name: 'Location', icon : 'location'},
    {name: 'Style', icon: 'style'},
    {name: 'Text', icon: 'text'},
    {name: 'Finish', icon: 'finish'}
  ]
  const matches = useMediaQuery('(max-width:800px)');
  const [selectedIndex, setSelectedIndex] = useState(0);
  

  useEffect(() => {
    if(matches) {
     setSelectedIndex(-1); 
    } else {
      setSelectedIndex(0);
    }
  },[matches]);

  return(
    <div>
      <MapArea />
      <div id="tabs">
        <div className="tab">
          <TabButtons 
            options={options} 
            selectedIndex={selectedIndex} 
            setSelectedIndex={setSelectedIndex}
          />
        </div>
        <ToolArea 
          selectedIndex={selectedIndex}
          setSelectedIndex={setSelectedIndex}
        />
      </div>
    </div>
  );

}

export default Base;