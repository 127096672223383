import React, {useCallback, useEffect} from 'react';
import { Typography, InputBase, SvgIcon, IconButton, Button, Checkbox
} from '@material-ui/core';
import {useSelector, useDispatch} from 'react-redux';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {setText} from '../../redux/actions'
import { useStyles} from './Text.style'
import { convertDMS } from "../../utils/latlongtoDMS";

const Text = (props) => {

  const matches = useMediaQuery('(max-width:800px)')
  const classes = useStyles({matches});
  const dispatch = useDispatch();

  const text = useSelector(state => state.map.text);
  const location = useSelector(state => state.map.location);


  const updateText = useCallback(
    (text) => dispatch(setText(text)),
    [dispatch]
  );


  const updateTitle = (e) => {
    updateText({...text, title: e.target.value})
  }

  const updateSubtitle = (e) => {
    updateText({...text, subtitle: e.target.value})
  }
  const setUseCoordinates = () => {
    const {useCoordinate} = text;
    if(!useCoordinate)
      updateText({...text, useCoordinate: !useCoordinate, subtitle: convertDMS(location.lat, location.lng)});
    else
      updateText({...text, useCoordinate: !useCoordinate});
  }

  const hideText = () => {
    const {isHidden} = text;
    updateText({...text, isHidden: !isHidden});
  }
  


  return (
    <>
      <h1 className={classes.title}> Edit the text </h1>
      <form className={classes.formItem}>
        <label> Title </label>
        <InputBase
          classes={{
            root: classes.inputRoot,
            input: classes.inputInput,
          }}
          value={text.title}
          onChange={updateTitle}
        />
        <label> Subtitle </label>
        <InputBase
          classes={{
            root: classes.inputRoot,
            input: classes.inputInput,
            disabled: classes.disabled
          }}
          value={text.subtitle}
          disabled={text.useCoordinate}
          onChange={updateSubtitle}
        />
      </form>
      <div className={classes.checkbox}>
        <Checkbox
          checked={text.useCoordinate}
          onChange={setUseCoordinates}
        />
        <Typography variant="body1">Use coordinates as subtitle</Typography>
      </div>
      <div className={classes.checkbox}>
        <Checkbox
          checked={text.isHidden}
          onChange={hideText}
        />
        <Typography variant="body1">Hide the text</Typography>
      </div>
      
    </>
  )
}

export default Text;


