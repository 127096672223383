import React, {useEffect, useLayoutEffect, useState, useRef, useCallback} from 'react';
import L from "leaflet";
import {useSelector, useDispatch} from 'react-redux'
import domtoimage from 'dom-to-image';
import { useWindowSize } from "../../styles/responsive";
import { setLocation, setText, setOrder, setIsLoading } from '../../redux/actions'
import { convertDMS } from "../../utils/latlongtoDMS";
import { addToCart } from '../../utils/addToCart'
import { sizes } from "../../constants";
import './index.css'

const URI = 'https://kaartenzijncool.printmijnstad.nl/';
const PARAM = '/{z}/{x}/{y}';
const labelPARAM = "labels_modern/{z}/{x}/{y}";

const MapArea = () => {

  const windowSize = useWindowSize();
  const [scale, setScale] = useState(0.7);
  const [isLocationChanged, setIsLocationChanged ] = useState(false);
  const [width, setWidth] = useState('600px');
  const [height, setHeight] = useState(`${600*(sizes[1].height/ sizes[1].width)}px`);
  const [borderStyle, setBorderStyle] = useState({});
  const map = useRef(null);
  const layer = useRef(null);
  const labelLayer = useRef(null);
  const _node = useRef(null);

  const location = useSelector(state => state.map.location);
  const style = useSelector(state => state.map.style);
  const size = useSelector(state => state.map.size);
  const text = useSelector(state => state.map.text);
  const order = useSelector(state => state.map.order);

  const dispatch = useDispatch();

  const updateLocation = useCallback(
    (location) => dispatch(setLocation(location)),
    [dispatch]
  );

  const updateIsLoading = useCallback(
    (state) => dispatch(setIsLoading(state)),
    [dispatch]
  )
  
  const updateText = useCallback(
    (text) => dispatch(setText(text)),
    [dispatch]
  )

  const updateOrder = useCallback(
    (state) => dispatch(setOrder(state)),
    [dispatch]
  );

  const locationChanged = () => {
    setIsLocationChanged(true);
  }

  useEffect(() => {
    if(isLocationChanged) {
      updateLocation({
        ...location,
        lat: map.current.getCenter().lat,
        lng: map.current.getCenter().lng,
        zoom: map.current.getZoom()
      });
      updateText({
        ...text,
        subtitle: convertDMS(map.current.getCenter().lat, map.current.getCenter().lng)
      })
      setIsLocationChanged(false);
    }
  }, [isLocationChanged])

  const _filter = (node) => {
    if(node.id === 'v-label' || node.id === 'h-label') {
      return false;
    }
    if(node.className === "leaflet-control-container")
      return false;

    return true;
  }

  const _upload_image = async () => {
    updateIsLoading(true)
    try {
      await domtoimage.toJpeg(_node.current, { quality: 0.1, filter: _filter, bgcolor: 'white' })
      const imgURL = await domtoimage.toJpeg(_node.current, { quality: 1, filter: _filter, bgcolor: 'white', style: {
        paddingTop: '200px'
      } })
      updateOrder(false);
      addToCart({imgURL, location, style, text, size});
    } catch(e) {
      updateIsLoading(false);
    }
    updateIsLoading(false);
    return Promise.resolve();
  }

  useEffect(() => {
    if(order)
      _upload_image()
  }, [order])

  useEffect(() => {
    map.current = L.map('map', {attributionControl: false}).setView([52.3669, 4.8944091], 12);
    labelLayer.current = L.tileLayer(URI + labelPARAM + (L.Browser.retina ? "@2x" : "") +  '.png', {
      minZoom: 3,
      maxZoom: 22,
      attribution: "",
      opacity: 1
    });
    map.current.on("moveend", locationChanged)
    getContainerDimenssion();   
  },[])

  

  useEffect(() => {
    const {type} = style;
    if(map.current) {
      if(layer.current) {
        map.current.removeLayer(layer.current);
      }
      if(location.showCityLabels) {
        map.current.removeLayer(labelLayer.current);
      }
      layer.current = L.tileLayer(URI + type + PARAM + (L.Browser.retina ? "@2x" : "") +  '.png', {
          minZoom: 3,
          maxZoom: 22,
          attribution: "",
          opacity: 1
      });
      map.current.addLayer(layer.current);
      if(location.showCityLabels) {
        map.current.addLayer(labelLayer.current);
      }
    }
  }, [style.type]);

  useEffect(() => {
    const {lat, lng, zoom} = location;
    if(map.current) {
      map.current.setView(new L.LatLng(lat,lng), zoom);
    }
  }, [location.lan, location.lng, location.zoom]);

  useEffect(() => {
    const {showCityLabels} = location;
    if(map.current) {
      if(showCityLabels) {
        map.current.addLayer(labelLayer.current);
      } else {
        map.current.removeLayer(labelLayer.current);
      }
    }
  },[location.showCityLabels]);  

  useEffect(() => {
    getContainerDimenssion()
    setTimeout(() => {
      if(map.current) {
        map.current.invalidateSize();
        map.current.setView(map.current.getCenter(), map.current.getZoom());
      }
    }, 400);
    
  }, [size.index, style.isHorizontal])

  useEffect(() => {
    if(size.isFramed) {
      setBorderStyle({
        border: '0 solid #2B272A',
        borderStyle: 'groove',
        borderWidth: '0.47em',
        borderRadius: '0.2em'      
      })
    } else {
      setBorderStyle({});
    }
  }, [size.isFramed])

  useLayoutEffect(() => {
    changeScale();
  },[windowSize, style.isHorizontal]);

  const changeScale = () => {
    let avlblHeight = windowSize[1];
    let avlblWidth = windowSize[0];
    if(avlblWidth >= 800) {
      avlblWidth = avlblWidth - 500; 
    } else {
      avlblHeight -= 64;
    } 
    const poseterDimX = sizes[size.index].width;
    const posterDimY = sizes[size.index].height;
    let fixed_width = 620;
    let fixed_height = fixed_width*(posterDimY / poseterDimX);
    if(style.isHorizontal && windowSize[0] > 800)
      setScale(Math.min((avlblWidth / fixed_width)*0.67, (avlblHeight / fixed_height)*0.65))
    else
      setScale(Math.min((avlblWidth / fixed_width)*0.57, (avlblHeight / fixed_height)*0.55))
  }

  const mapOutlineStyle = {
    transform: `translate(-50%, -53%)` + 
      `scale(${scale})`
  };

  const getContainerDimenssion = () => {
    const _size = sizes[size.index];
    const _width = _size.width;
    const _height = _size.height;
    let FIXED_SIZE = 620;
    if(style.isHorizontal && windowSize[0] > 800)
      FIXED_SIZE = 720
    let fixed_width = FIXED_SIZE + ((_width/11) == 1 ? 100 : (_width / 11)*100 ) ;
    if(style.isHorizontal)
      fixed_width = FIXED_SIZE;
    let fixed_height = fixed_width*(_height / _width);
    if(style.isHorizontal) {
      fixed_height = [fixed_width, fixed_width = fixed_height][0]; 
    }
    setWidth(`${fixed_width}px`);
    setHeight(`${fixed_height}px`);
  }

  

  return (
    <div id="container">
      <div style={{ width: '100%', height: '100%', marginTop : '100px'}} ref={_node}>
          <div id="mapoutline" style={mapOutlineStyle}>
            <div id="mapcontainer" style={{...borderStyle,height, width}} >
              <div id="map-black-border" >
                <div id="map" />
                {text.isHidden === false && 
                  <div id="labels"><h1 className="city">{text.title}</h1> <p style={{textAlign: 'center'}}>{text.subtitle}</p></div>     
                }
              </div>
              <div id="v-label" style={{width: height}}>{sizes[size.index].height}inch</div>
              <div id="h-label">{sizes[size.index].width}inch</div>
            </div>
            </div>
        </div>
      </div>
  )
}

export default MapArea;